import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/actionable.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import actionablePage from '../images/actionable-pg.svg'

import actionable1 from '../images/actionable-s1.svg'
import actionable2 from '../images/actionable-s2.svg'
import actionable3 from '../images/actionable-s3.svg'
import actionable4 from '../images/actionable-s4.svg'
import actionable5 from '../images/actionable-s5.svg'
import actionable6 from '../images/actionable-s6.svg'
import actionable7 from '../images/actionable-s7.svg'
import actionable8 from '../images/actionable-s8.svg'
import actionable9 from '../images/actionable-s9.svg'
import actionable10 from '../images/actionable-s10.svg'
import actionable11 from '../images/actionable-s11.svg'
import actionable12 from '../images/actionable-s12.svg'
import actionable13 from '../images/actionable-s13.svg'

const Actionable = ({ data }) => {
	const state = { productName: 'Actionable Insights', planTitle: 'Starter Plan', planPrice: '100 $' }
	const signupClick = () => {
		localStorage.setItem(
			'state',
			JSON.stringify({
				productName: state.productName,
				planTitle: state.planTitle,
				planPrice: state.planPrice,
			})
		)
	}
	return (
		<Fragment>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Layout>
				{/* <SEO
				title="Actionable Insights"
				description="Platform is the best way to control your
											industry easy and fast, Now Drive to the
											future"
			/> */}
				<div className="actionable-page">
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div className="page-content actionable-content">
						<div className="actionable-section">
							<div className="header-content">
								<div className="header-items">
									<h1>Actionable Insights</h1>
									<div className="hint">
										<h3>
											<span>Services Cloud Ready</span>
											<span>
												{' '}
												Platform is the best way to control
												your industry easy and fast, Now
												Drive to the future.
											</span>
										</h3>
									</div>
									<CustomButton>
										<Link
											className="demo-request"
											data-track-location="idataworkers"
											to="/pricing"
										>
											Request Demo
										</Link>
									</CustomButton>
								</div>
								<div className="header-imgs">
									<img src={actionablePage} alt="services-left" />
								</div>
							</div>
						</div>
						<section className="products-content" id="products-content">
							<h1 className="product-header">Explore Features</h1>
							<div className="products-container">
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												HR and poeple analytics, actions and
												dashboard
											</h3>
											<p className="subtitle">
												HR departments focus on human
												behavior, but what is driving
												employee choices? With enough data
												and the right tools, the how and why
												behind individual decisions and
												performance can be understood.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable1}
												className="item-img"
												alt="E-Commerce"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Industry Specific Analytics
											</h3>
											<p className="subtitle">
												The How and Why Behind Your
												Industry, idataworkers gives
												organizations the ability to ingest,
												process, and view their industry
												specific data in real time. You can
												analyze your customers information.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable11}
												className="item-img"
												alt="Branches"
											/>
										</div>
									</div>
								</div>

								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Tomorrow’s Finance Analytics
												Capabilities, Today
											</h3>
											<p className="subtitle">
												Executives now expect more from
												finance than accurate financial
												statements and reports. They need
												forward-looking, predictive insights
												from a growing mountain of data that
												can help shape tomorrow’s business
												strategies and improve day-to-day
												decision-making in real time. Beyond
												the “what”, finance must now report
												on “why” and “how”. Leverage All
												Data Data comes from everywhere, not
												just your ERP or EPM systems. It
												comes from various departments,
												third-party information, Internet of
												Things (IoT) applications, and many
												more sources.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable13}
												className="item-img"
												alt="Branches"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Increase Efficiency While
												Discovering New Possibilities
											</h3>
											<p className="subtitle">
												In the era of digital business, the
												supply chain process is filled with
												data. From order management to
												procurement and inventory management
												processes, the entire cycle creates
												data signals across every phase to
												report on quantities, shipping
												dates, and procurement spans.
												Idataworkers Analytics for Supply
												Chain Management enables a modern
												overhaul of any organization’s
												supply chain management processes,
												from operations to inventory to
												logistics to sales.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable5}
												className="item-img"
												alt="Personalized"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details library">
											<h3 className="title">
												A comprehensive library of best
												practice metrics incorporates our
												extensive knowledge of business apps
												data
											</h3>
											<p className="subtitle">
												This comprehensive library of best
												practice metrics incorporates our
												extensive knowledge and experience
												in building business applications.
												Spanning all business functions and
												roles, the best practice metrics
												library will include strategic,
												operational, and process metrics
												that can be benchmarked internally
												or against a peer group.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable2}
												className="item-img"
												alt="Catalog"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Email, SMS, and push notifications
											</h3>
											<p className="subtitle">
												Notifiy your executive with an email
												message or smsm when a specific
												action such as purchasing /
												canceling subscription or any
												important business action.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable3}
												className="item-img"
												alt="Service"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Custom branding and localization
											</h3>
											<p className="subtitle">
												Idataworkers can be customized with
												a look and feel that aligns with
												your organization's brand
												requirements and user expectations.
												Branding Idataworkers collateral
												provides a consistent user
												experience for your users, and gives
												them peace of mind that they’re
												using a product from a trusted and
												secure provider.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable4}
												className="item-img"
												alt="Marketing"
											/>
										</div>
									</div>
								</div>

								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Online data sources and warehouse
												option
											</h3>
											<p className="subtitle">
												Analyze and uncover the hidden
												insights from the data present in
												your system of records directly or
												through connecting to idataworkers
												warehouse
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable6}
												className="item-img"
												alt="Objects"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Sensitive data management
											</h3>
											<p className="subtitle">
												Designed to help you meet the
												privacy standards set by the EU,
												Idataworkers has multiple
												GDPR-centric enhancements that
												provide a streamlined mechanism,
												from storing data to handling data
												subject rights. This way you don't
												have to worry about compliance and
												focus more on running your business.{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable7}
												className="item-img"
												alt="Gallery"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details">
											<h3 className="title">
												Reading from more than 30 different
												data sources
											</h3>
											<p className="subtitle">
												Idataworkers reads from most of the
												top system of records and industry
												specific solutions and data sources.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable8}
												className="item-img"
												alt="Promotions"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details">
											<h3 className="title">
												Help articles, support and
												documentation
											</h3>
											<p className="subtitle">
												Our architect and support team are
												ready to support you anytime to
												achieve your objectives{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable9}
												className="item-img"
												alt="Voice"
											/>
										</div>
									</div>
								</div>
								<div className="product-wrapper">
									<div className="product-parent reverse-parent">
										<div className="product-details sql">
											<h3 className="title">
												SQL and no-sql databases ,cloud or
												On-premise databases
											</h3>
											<p className="subtitle">
												You can get your data into
												idataworkers from any source for
												powerful analysis and reporting on
												both SQL and NoSQL data sources{' '}
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable10}
												className="item-img"
												alt="Supports"
											/>
										</div>
									</div>
								</div>

								<div className="product-wrapper">
									<div className="product-parent">
										<div className="product-details prediction">
											<h3 className="title">
												Prediction, Classification, and
												various features via ML models
											</h3>
											<p className="subtitle">
												Idataworkers enables you to
												effectively predict your future data
												trends, using its forecasting and ML
												feature. Forecasting in Idataworkers
												is based on powerful forecasting
												algorithms which analyzes your past
												data deeply and comes up with the
												best forecast for the future.
											</p>
											<CustomButton>
												<Link
													className="product-btn"
													to="/signup"
													state={state}
													onClick={signupClick}
												>
													Get Trial
												</Link>
											</CustomButton>
										</div>
										<div className="product-img">
											<img
												src={actionable12}
												className="item-img"
												alt="Supports"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}
export const pageQuery = graphql`
	query ActionableQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Actionable
